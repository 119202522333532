<template lang="pug">
  div(class="fan-gif")
    div(v-if="loading")
      img(:src="fanGif" class="responsive")
      div(name="loadingMsg")
    div(v-else)
      div
</template>

<script>
export default {
  name: 'simple-fan-loader',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    fanGif: require('../assets/fan_xxdpi.gif'),
  }),
}

</script>