<template lang="pug">
  div
    img.hero(
      :src="successPlane"
      style="max-height=150px"
    )

    h1.header Way to go!

    .body
      slot

</template>

<script>
  export default {
    data() {
      return {
        successPlane: require('@/assets/success-plane.svg'),
      }
    },
  }
</script>

<style>
  img.hero {
    max-height: 150px;
  }

  .header {
    text-align: center;
  }
</style>
