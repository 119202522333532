<template lang="pug">
  form
    .field
      .radio
        input(
          v-model="focusedField"
          type="radio"
          name="field"
          value="email"
        )
      .text
        .label Email
        input#email(
          v-model="email"
          type="text"
          placeholder="example@mail.com"
          :class="!emailValid ? 'invalid' : ''"
          :disabled="focusedField!=='email'"
          @blur="validate"
          @focus="emailValid = true"
        )
        .err(:style="visibleIf(!emailValid)") Invalid email

    .separator or

    .field
      .radio
        input(
          v-model="focusedField"
          type="radio"
          name="field"
          value="phone"
        )
      .text
        .label Phone
        input#phone(
          v-model="phone"
          type="text"
          placeholder="8003217654"
          :class="!phoneValid ? 'invalid' : ''"
          :disabled="focusedField!=='phone'"
          @blur="validate"
          @focus="phoneValid = true"
        )
        .err(:style="visibleIf(!phoneValid)") Invalid phone number

</template>

<script>
  export default {
    data() {
      return {
        email: '',
        phone: '',
        focusedField: 'email',
        emailValid: true,
        phoneValid: true,
      }
    },
    computed: {
      focusFieldValue() {
        return (this.focusedField === 'email')
          ? this.email
          : (this.focusedField === 'phone')
            ? this.phone
            : null
      },
    },
    watch: {
      focusedField() {
        this.validate()
      },
      focusFieldValue(val) {
        const x = this.focusedField

        const submittable = (
          this[x].length > 0
          && this[x+'Check']()
        )

        const y = (submittable)
          ? { [x]: val }
          : false

        if (x === 'phone') y[x] = '+1' + val

        this.$emit('input', y)
      },
    },
    methods: {
      customClass(field) {
        return (this.focusedField !== field) ? 'greyed-out' : ''
      },

      emailCheck() {
        return !this.email || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email)
      },

      phoneCheck() { // XXX-XXX-XXXX
        return !this.phone || /^[0-9]{10}$/.test(this.phone)
      },

      validate() {
        let valid
        const field = this.focusedField

        if (field === 'email') {
          valid = this.emailCheck()
        } else if (field === 'phone') {
          valid = this.phoneCheck()
        }

        //set emailValid or phoneValid
        this[field + 'Valid'] = valid
      },

      visibleIf(check) {
        return (check)
          ? { visibility: 'visible' }
          : { visibility: 'hidden' }
      },

    },
  }
</script>

<style lang="scss">
  @import '@/styles/base';

  .field {
    display: flex;
    align-items: center;
    height: 90px;
    margin-bottom: 15px;

    & .radio {
      margin-right: 10px;

      & input:checked:after {
        width: 13px;
        height: 13px;
        border-radius: 15px;
        top: -2px;
        left: -2px;
        position: relative;
        background-color: $primaryColor;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }
    }

    & .label {
      .label {
        font-size: 0.8em;
        margin-bottom: 5px;
      }
    }

    & .text input {
      font-size: 1em;
      border: solid 1px $primaryColor;
      border-radius: 30px;
      padding: 8px;
      margin: 5px 0;

      &[disabled] {
        background-color: $disableGrey;
        border: solid 1px $disableGrey;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 5px $primaryColor;
      }

      &.invalid {
        border-color: red;

        &:focus {
          box-shadow: 0 0 5px red;
        }
      }
    }

    & .err {
      color: red;
    }
  }

  .separator {
    color: $disableGrey;
    margin-bottom: 15px;
  }

</style>
