import axios from 'axios'
import envVars from '@/helpers/env_vars'

const { slsBaseUrl, c4cBaseUrl } = envVars

export const init = (guid) => {
  return axios.get(slsBaseUrl +
    `/authenticate/easy-button/${guid}`)
    .then(({ data }) => {
      if (data) {
        return data
      } else {
        console.error('Easy button data not found for guid', guid)
        return null
      }
    })
}

export const sendEasyLink = (payload, resolve, reject) => {
  const _payload = {
    ...payload,
    urlHost: c4cBaseUrl,
  }

  console.log('sending EasyLink', _payload)
  return axios.post(slsBaseUrl +
    '/authenticate/easy-link/create',
    _payload
  )
  .then(data => resolve(data))
  .catch(err => reject(err))
}

export default {
  init,
  sendEasyLink,
}
