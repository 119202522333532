<template lang="pug">
  transition(name="modal")
    .modal-mask
      .modal-wrapper
        .modal-container

          .top-bar
            img#close-btn.gtm_close(
              :src="closeSvg"
              @click="$emit('close')"
            )

          template(v-if="flowState === 'UNSENT'")
            h1 Send yourself a link to apply

            .terms(
              v-html="terms"
            )

            ElinkSendForm( @input="updateSendto" )

            CtaBtn(
              @click="sendEasyLink"
              :disabled="submitDisabled"
            ) Send

          template(v-else-if="flowState === 'EXISTS'")
            h1.header Easy Link exists already

          template(v-else-if="flowState === 'SUCCESS'")
            Success
              | Please check <strong>{{ sentTo }}</strong> for the Credit for Comfort Easy Link and get approved in seconds.

          template(v-else-if="flowState === 'ERROR'")
            h1.header Sorry, we could not send the link at this time. Please try again.

          template(v-else-if="flowState === 'LOADING'")
            SimpleFanLoader(
              :loading="loading"
              class="header"
            ) 
            h1.header Sending Easy Link...

          .footer
            #secure
              img( :src="lockSvg" )
              | 256-BIT SSL PROTECTION
            .logo
              img( :src="poweredBy" )

</template>

<script>
  import ElinkSendForm from '@/components/easy-link/SendForm.vue'
  import Success from '@/components/easy-link/SuccessResponse.vue'
  import Api from '@/helpers/api'
  import CtaBtn from '@/components/CtaBtn'
  import SimpleFanLoader from '@/components/SimpleFanLoader'

  export default {
    props: {
      guid: {
        type: String,
        default: "fb39506ae512777e0fbb3fc1e6a02f4b",
      },
    },
    components: {
      CtaBtn,
      ElinkSendForm,
      Success,
      SimpleFanLoader,
    },
    data() {
      return {
        closeSvg: require('@/assets/close_X.svg'),
        dealerInfo: null,
        errMsg: '',
        existingEasyLink: '',
        lockSvg: require('@/assets/lock.svg'),
        poweredBy: require('@/assets/power_by_c4c.svg'),
        loading: false,
        sendTo: null,
        submitDisabled: true,
        successMsg: '',
        terms: `<p>For Credit for Comfort Terms of Service, 
                <a class="terms" target="_blank" href="https://www.creditforcomfort.com/terms-of-service/"> 
                click here</a></p>`,
      }
    },
    computed: {
      flowState() {
        let status = 'UNSENT';
        
          if (this.loading)
            status = 'LOADING';

          else if (this.successMsg && !this.errMsg)
            status = 'SUCCESS';

          else if (this.existingEasyLink)
            status = 'EXISTS';
          
          else if (!this.successMsg && this.errMsg)
            status = 'ERROR';
        return status;
      },
      sentTo() {
        return Object.values(this.sendTo)[0]
      },
    },
    mounted() {
      Api.init(this.guid)
        .then(x => {
          this.dealerInfo = x
          console.log('dealerInfo', x)
        })
    },
    methods: {
      sendEasyLink() {
        this.loading = true

        const payload = {
          ...this.sendTo,
          ...this.dealerInfo,
          source: 'comfortbutton',
        }

        console.log('sendEasyLink', payload)
        Api.sendEasyLink(payload,
          res => {
            this.loading = false
            if (res.data) {
              if (res.data.err) {
                if (res.data.err.code === 'UsernameExistsException') {
                  this.errMsg = 'Easy link already exists.'
                  this.existingEasyLink = res.data.guid
                } else {
                  this.errMsg = 'Server error:' + res.data.err.message
                }
              } else if (res.data.message) {
                this.successMsg = res.data.message
              } else {
                this.successMsg = 'Done!'
              }
            } else {
              this.errMsg = 'Not sure why, but we couldn\'t send your easy link. Contact us for more help.'
            }
          },
          err => {
            this.loading = false
            this.errMsg = 'Not sure why, but we couldn\'t send your easy link. Contact us for more help.'
          }
        )
      },

      updateSendto(x) {
        if (x !== false) {
          this.sendTo = x
          this.submitDisabled = false
        } else {
          this.submitDisabled = true
        }
      },
    },
  }
</script>

<style lang="scss">
  @import '@/styles/base';

  .top-bar {
    text-align: right;
  }

  .terms {
    
    font-size: xx-small;
    color: #757575;
    padding-bottom: 30px;
    
    a {
      color: #3B73B8;
    }
  }


  #close-btn {
    cursor: pointer;
    height: 15px;
  }

  button#send {
    background: white;
    font-size: 1.2em;
    margin-top: 10px;
  }

  .footer {
    display:flex;
    justify-content:space-between;
    margin-top: 30px;

    & .logo img {
      max-height: 25px;
    }

    & #secure {
      color: #b9bbbe;
      font-size: 0.6em;
      margin: auto 0;

      & img {
        margin-right: 5px;
        max-height: 12px;
        vertical-align: bottom;
      }
    }
  }

  .header {
    text-align: center;
  }


  .modal-mask {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 500px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
