<template lang="pug">
 div
  .card
    img(:src="logo")
    .col#title-col
      .title
        .break(
          v-for="title in titleBreaks" :key="title"
          v-html="title"
        )
      .subtitle {{ subtitle }}

    CtaBtn(
      @click="showModal = true"
    )
      span.mr-2-xs Apply
      span.hidden-xs Now

  SendModal(
    v-if="showModal"
    :guid="guid"
    @close="showModal = false"
  )
</template>

<script>
  import SendModal from '@/components/EasyLinkModal'
  import Api from '@/helpers/api'
  import CtaBtn from '@/components/CtaBtn'

  export default {
    props: {
      guid: {
        type: String,
        //TODO remove default and handle if prop not passed
        default: "87cc3c2130def4232b160b5b43a49b6e",
      },
    },
    components: {
      CtaBtn,
      SendModal,
    },
    data () {
      return {
        logo: require('@/assets/logo_isotype.png'),
        showModal: false,
        subtitle: '100% Paperless | Decision in seconds | Fully Secure',
        titleBreaks: [
          'Get your Comfort Now!',
          'Pay over time!',
        ],
      }
    },
  }
</script>

<style lang="scss">
  .card {
    background: #fafafa;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Lato', sans-serif;
    font-size: 14px;

    border: #e0e0e0 1px solid;
    border-radius: 10px;

    padding: 10px;

    & img {
      max-height: 54px;
      width: auto;
    }

    & .subtitle {
      display: none;
      //color: #757575;
      //font-size: 0.6em;
    }
  }

  .col {
    display: flex;
    flex-flow: column;
  }

  .hidden-xs {
    display: none;
  }

  @media only screen and (min-width: 350px) {
    .hidden-xs {
      display: inherit;
    }

    .mr-2-xs {
      margin-right: 0.3em;
    }

    .card {
      & .subtitle {
        display: inherit;
        color: #757575;
        font-size: 0.6em;
      }
    }

    #title-col {
      margin-right: 10px;
    }
  }

  .title {
    display: flex;
    flex-flow: wrap;

    & .break {
      margin-right: 0.3em;
    }
  }

</style>
